import fetch from '../util/fetch';

// 取得所有操作員資料
export function getAllOperator(reqData) {
	return fetch({
		url: '/cars/authority/operator',
		method: 'post',
		data: {
			reqData,
		},
	});
}
// 新增操作員資料
export function addOperator(reqData) {
	return fetch({
		url: '/cars/authority/addOperator',
		method: 'post',
		data: {
			reqData,
		},
	});
}
// 更新操作員資料
export function updateOperator(reqData) {
	return fetch({
		url: '/cars/authority/updateOperator',
		method: 'post',
		data: {
			reqData,
		},
	});
}
// 更新操作員帳號密碼
export function updatePassword(reqData) {
	return fetch({
		url: '/cars/authority/updatePassword',
		method: 'post',
		data: {
			reqData,
		},
	});
}
// 修改密碼
export function checkPassword(reqData) {
	return fetch({
		url: '/cars/authority/checkPassword',
		method: 'post',
		data: {
			reqData,
		},
	});
}

// 修改群組名稱
export function updateGroupName(reqData) {
	return fetch({
		url: '/cars/authority/updateGroupName',
		method: 'post',
		data: {
			reqData,
		},
	});
}

// 取得群組列表
export function getGroupList(reqData) {
	return fetch({
		url: '/cars/authority/getGroupList',
		method: 'post',
		data: {
			reqData,
		},
	});
}

// 取得全部頁面
export function getAllItemList(reqData) {
	return fetch({
		url: '/cars/authority/getAllItemList',
		method: 'post',
		data: {
			reqData,
		},
	});
}

// 取得全部站點名稱
export function getAllDomainList(reqData) {
	return fetch({
		url: '/cars/authority/getAllDomainList',
		method: 'post',
		data: {
			reqData,
		},
	});
}

// 取得不在任何群組的操作員
export function getOperatorNotInGroup(reqData) {
	return fetch({
		url: '/cars/authority/getOperatorNotInGroup',
		method: 'post',
		data: {
			reqData,
		},
	});
}

// 新增群組
export function addGroup(reqData) {
	return fetch({
		url: '/cars/authority/addGroup',
		method: 'post',
		data: {
			reqData,
		},
	});
}

// 取得群組所有權限
export function getGroupInfo(reqData) {
	return fetch({
		url: '/cars/authority/getGroupInfo',
		method: 'post',
		data: {
			reqData,
		},
	});
}

// 更新頁面權限
export function updateItem(reqData) {
	return fetch({
		url: '/cars/authority/updateItem',
		method: 'post',
		data: {
			reqData,
		},
	});
}

// 更新站點權限
export function updateDomain(reqData) {
	return fetch({
		url: '/cars/authority/updateDomain',
		method: 'post',
		data: {
			reqData,
		},
	});
}

// 新增操作員至群組
export function addOperatorToGroup(reqData) {
	return fetch({
		url: '/cars/authority/addOperatorToGroup',
		method: 'post',
		data: {
			reqData,
		},
	});
}

// 刪除操作員的群組
export function deleteOperatorFromGroup(reqData) {
	return fetch({
		url: '/cars/authority/deleteOperatorFromGroup',
		method: 'post',
		data: {
			reqData,
		},
	});
}
// 確認密碼
export function verifyPassword(reqData) {
	return fetch({
		url: '/cars/authority/verifyPassword',
		method: 'post',
		data: {
			reqData,
		},
	});
}