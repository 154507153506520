<template lang='pug'>
	v-container(grid-list-xl fluid)
		v-layout(row wrap)
			v-flex(lg12 sm12 xs12)
				v-data-table(:headers='headers' :items='operatorList' :items-per-page='10')
					template(v-slot:top)
						v-toolbar(flat)
							v-dialog(v-model='dialog' max-width='500px')
								template(v-slot:activator='{ on }')
									v-btn.defult-btn-color(dark rounded v-on='on') {{$t('admin.add')}}
								v-card
									v-card-title
										span.text-center(class='headline') {{ formTitle }}
									v-card-text
										v-container
											v-row
												v-col(cols='12' sm='6' md='6')
													v-text-field(v-if='editedIndex !== -1' disabled
														v-model='editedItem.operatorID' :label="$t('admin.operatorID')")
													v-text-field(v-else v-model='editedItem.operatorID' :label="$t('admin.operatorID')")
												v-col(cols='12' sm='6' md='6')
													v-text-field(v-model='editedItem.password' type='password' :label="$t('admin.password')")
												v-col(cols='12' sm='6' md='6')
													v-switch(v-model='editedItem.register' :label="$t('admin.registered')" color='cyan lighten-1')
													//- v-text-field(v-model='editedItem.register' :label="`${editedItem.register}`")
												v-col(cols='12' sm='6' md='6')
													v-switch(v-model='editedItem.enable' :label="$t('admin.enable')" color='amber lighten-1')
													//- v-text-field(v-model='editedItem.enable' label='Enable')
												v-col(cols='12' sm='6' md='6')
													v-switch(v-model='editedItem.otp' :disabled='!editedItem.otp' :label="$t('admin.otp')" color='amber lighten-1')
									v-card-actions
										div(class='flex-grow-1')
										v-btn.defult-btn-color(dark rounded @click='close') {{$t('admin.cancel')}}
										v-btn.defult-btn-color(dark rounded @click='save') {{$t('admin.save')}}
					template(v-slot:footer.page-text='{ pageStart, pageStop }') Items: {{ pageStart }} - {{ pageStop }}
					template(v-slot:item.register='{ item }')
						span(v-if='item.register')
							v-icon(large color='green lighten-1') check
						span(v-else)
							v-icon(large color='red lighten-1') close
					template(v-slot:item.enable='{ item }')
						span(v-if='item.enable')
							v-icon(large color='green lighten-1') check
						span(v-else)
							v-icon(large color='red lighten-1') close
					template(v-slot:item.otp='{ item }')
						span(v-if='item.otp')
							v-icon(large color='green lighten-1') check
						span(v-else)
							v-icon(large color='red lighten-1') close
					template(v-slot:item.action='{ item }')
						v-icon(small class='mr-2' @click='editItem(item)') edit
						//- v-icon(small @click='deleteItem(item)') delete
					
				//- template
				//- 	v-btn(color='primary' @click='initialize') Reset
</template>
<script>
import { addOperator, getAllOperator, updateOperator, updatePassword } from '@/api/Admin';
import { closeOTP } from '@/api/Config';
import { mapGetters } from 'vuex';
import statusCode from '@/assets/constant/statusCode';
import { roundOff } from '@/util/format';

export default {
	data() {
		return {
			page: 1,
			dialog: false,
			headers: [
				{
					text: this.$t('admin.operatorID'),
					align: 'left',
					sortable: false,
					value: 'operatorID',
				},
				{ text: this.$t('admin.registered'), value: 'register' },
				{ text: this.$t('admin.enable'), value: 'enable' },
				{ text: this.$t('admin.otp'), value: 'otp' },
				{ text: this.$t('admin.modify'), value: 'action', sortable: false },
			],
			operatorList: [],
			editedIndex: -1,
			pagination: {'sortBy': 'name', 'descending': true, 'rowsPerPage': -1},
			editedItem: {
				operatorID: '',
				password: '',
				register: 0,
				enable: 0,
				otp: false,
			},
			defaultItem: {
				operatorID: '',
				password: '',
				register: 0,
				enable: 0,
				otp: false,
			},
		};
	},
	methods: {
		async initialize () {
			let obj = {};
			const reqData = {
				groupID: this.getOperator.groupID,
			};
			const res = await getAllOperator(reqData);
			if (res.status === statusCode.STATUS_OK) {
				const result = res.result.res;
				for(let i of result){
					obj = {};
					obj.enable = i.active;
					obj.operatorID =  i.operator_ac;
					// obj.password = '';
					obj.register = i.reg;
					obj.otp = i.otp_secret;
					this.operatorList.push(obj);
					this.page = roundOff(this.operatorList.length / 10);
				}
			}
		},
		editItem (item) {
			this.editedIndex = this.operatorList.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},
		// deleteItem (item) {
		// 	const index = this.operatorList.indexOf(item);
		// 	confirm('Are you sure you want to delete this item?') && this.operatorList.splice(index, 1);
		// },
		close () {
			this.dialog = false;
			setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			}, 300);
		},

		async save () {
			if (this.editedIndex > -1) {
				const reqData = {
					enable: this.parse(this.editedItem.enable),
					operatorAC: this.editedItem.operatorID,
					operatorPW: this.editedItem.password,
					register: this.parse(this.editedItem.register),
					otp: this.editedItem.otp
				};
				if(this.editedItem.password){
					const res = await updatePassword(reqData);
					if (res.status === statusCode.STATUS_OK) {
						Object.assign(this.operatorList[this.editedIndex], this.editedItem);
					}
				}
				if(!this.editedItem.otp) {
					const tmp = {
						operatorID: this.getOperator.operatorID, // id
						operatorAC: this.editedItem.operatorID, // account
					}
					const res = await closeOTP(tmp);
					if (res.status === statusCode.STATUS_OK) {
						Object.assign(this.operatorList[this.editedIndex], this.editedItem);
					}
				}
				const res = await updateOperator(reqData);
				if (res.status === statusCode.STATUS_OK) {
					Object.assign(this.operatorList[this.editedIndex], this.editedItem);
				}
			} else {
				const reqData = {
					operatorAC: this.editedItem.operatorID,
					operatorPW: this.editedItem.password,
					register: this.editedItem.register,
					enable: this.editedItem.enable
				};
				const res = await addOperator(reqData);
				if (res.status === statusCode.STATUS_OK) {
					this.operatorList.push(this.editedItem);
				}
			}
			this.close();
		},
		parse(response) {
			response = !!+response;
			return response;
		}
	},
	watch: {
		dialog (val) {
			val || this.close();
		},
	},
	computed: {
		formTitle () {
			return this.editedIndex === -1 ? this.$t('admin.addOperator') : this.$t('admin.operatorModification');
		},
		...mapGetters([
			'getOperator',
			'getDomain',
		]),
	},
	created() {
		this.initialize();
	}
};
</script>
